<template>
    <v-container class="session-container fill-height" fluid>
    <v-overlay :value="verifying">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-row  v-if="!verifying" justify="center" align="center">
      <v-col cols="12" sm="8" md="4">

        <v-col cols="12" class="d-flex justify-center">
            <h1 class="white--text">✪ AuditEngine</h1>
        </v-col>

        <v-col cols="12" class="d-flex justify-center">
          <h3>{{text}}</h3>
        </v-col>

        <v-flex v-if="verifySuccess" xs12 md12 lg12 class="text-center" style="margin-top: 50px;">
            <a class="underlined-link" @click="$router.push('/session/login')">Go to login page</a>
        </v-flex>

      </v-col>
    </v-row>
  </v-container> 
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    name: 'Verify',
    props: ['token'],
    components: {},
    async mounted() {
        this.verifying = true
        let response = await this.$store.dispatch("getTokenValidity", this.token)
        if(response.error){
            this.verifySuccess = false
            this.text = response.error
            this.verifying = false
        }
        else {
            this.verifying = false
            this.verifySuccess = true
            this.text = response.message
        }
    },
    data() {
      return {
          verifying: false,
          verifySuccess: false,
          text: '',
        }
    },
    watch: {

    },
 
  }
</script>

<style scoped>
.underlined-link {
    text-decoration: underline;
    color: white;
}
</style>